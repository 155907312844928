import React from "react";
import { BiNetworkChart } from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { FaStamp, FaUsersCog } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";
import { MdChecklist, MdOutlinePriceChange } from "react-icons/md";
import { RiListSettingsLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { PiToolboxDuotone } from "react-icons/pi";

interface Props {
  name?: string;
}

const SettingsList: React.FC<Props> = () => {
  return (
    <div className="">
      <div className="container px-4 py-5" id="icon-grid">
        <h2 className="pb-2 border-bottom">Settings</h2>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/company">
              <IoBusiness
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/company">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Company
                </h3>
              </Link>
              <p>Manage your company information and preferences.</p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/integrations">
              <BiNetworkChart
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link
                className="text-decoration-none"
                to="/settings/integrations"
              >
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Integrations
                </h3>
              </Link>
              <p>
                Connect your account to third-party services to make it easier
                to manage your business.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/users">
              <FaUsersCog
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/users">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Users</h3>
              </Link>
              <p>
                Manage your users and their permissions to access your account.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/form-inputs">
              <RiListSettingsLine
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/form-inputs">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Form Inputs
                </h3>
              </Link>
              <p>
                Manage your selection lists to make it easier to create new
                jobs.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/labels">
              <BsTags
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/labels">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">Labels</h3>
              </Link>
              <p>
                Manage your labels to make it easier to organize and find your
                leads or jobs.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/checklists">
              <MdChecklist
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/checklists">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Checklists
                </h3>
              </Link>
              <p>
                Manage your checklists to make it easier to track your progress
                on a job or lead.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link
              className="text-decoration-none"
              to="/settings/proposal-templates"
            >
              <FaStamp
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link
                className="text-decoration-none"
                to="/settings/proposal-templates"
              >
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Proposal Templates
                </h3>
              </Link>
              <p>
                Create and manage your templates to make it easier to create new
                job proposals.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/prices">
              <MdOutlinePriceChange
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/prices">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Prices & Taxes
                </h3>
              </Link>
              <p>
                Manage pallets deposit and Portland prices, and sales tax rates
                to streamline the process of generating purchase orders and
                invoices.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <Link className="text-decoration-none" to="/settings/toolbox">
              <PiToolboxDuotone
                className="bi text-body-secondary flex-shrink-0 me-3"
                style={{ fontSize: "1.75em" }}
              />
            </Link>
            <div>
              <Link className="text-decoration-none" to="/settings/toolbox">
                <h3 className="fw-bold mb-0 fs-4 text-body-emphasis">
                  Toolbox
                </h3>
              </Link>
              <p>
                Several tools to help you to import and export data, and to
                manage your account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
