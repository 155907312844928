import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchAccountInfo = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/account-info");
    console.log("account info fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchAccountInfo " + err);
    throw err;
  }
};

export const updateAccountInfo = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/account-info", data);
    console.log("account info updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateAccountInfo " + err);
    throw err;
  }
};

export const updateAccountLogo = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/account-logo", data);
    console.log("account logo updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateCompanyLogo " + err);
    throw err;
  }
};

export const fetchAccounts = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/accounts");
    console.log("accounts fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchAccounts " + err);
    return { error: err.message };
  }
};

export const createAccount = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/accounts", data);
    console.log("account created ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on createAccount " + err);
    return { error: err.message };
  }
};

export const updateAccount = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/accounts", data);
    console.log("account updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateAccount " + err);
    return { error: err.message };
  }
};

export const deleteAccount = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/accounts/" + id);
    console.log("account deleted ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on deleteAccount " + err);
    return { error: err.message };
  }
};
